.BgPosConfig {
	padding: 1.2rem;
	border-radius: 0.4rem;

	& > [class$="-Slider"] {
		background-color: transparent;
		width: 100%;
		height: auto;
		aspect-ratio: 1;
		overflow: visible;

		& > div > div {
			width: 30px;
			height: 30px;
		}
	}
}

.ColorTab .CustomGPicker > .popup_tabs {
	box-shadow: none;
	border: 1px solid rgb(231, 231, 231);
}

.ColorTab .CustomGPicker.dark > .popup_tabs {
	border: 1px solid rgb(32, 32, 32);
}
