.Toolbar {
	position: fixed;
	bottom: 0;
	color: white;
	padding: 0.5rem 1rem;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
	left: 50%;
	transform: translate(-50%);

	.ToolbarPopper {
		transition-duration: 0.5s;
		transform: translate(-65px, 0px);
		width: fit-content;
		background-color: transparent;
	}

	button.button {
		position: relative;
	}

	button.button:after {
		border-bottom: 1px solid red;
		transition-duration: 1s;
	}

	button.button.active:after {
		content: "";
		border-bottom: 1px solid rgb(167, 167, 167);
		width: 100%;
		display: block;
		margin-top: 5px;
		position: absolute;
		transition-duration: 1s;
	}
}

.dark .Toolbar {
	button.button.active:after {
		content: "";
		border-color: white;
	}
}

@media screen and (min-width: 1000px) {
	.Toolbar {
		top: 0;
		bottom: unset;
		border-bottom-left-radius: 0.3rem;
		border-bottom-right-radius: 0.3rem;
	}
	.ToolbarPopper {
		transition-duration: 0.8s;
		position: fixed !important;
		right: 0 !important;
		transform: translate(28vw, 4vh) !important;
	}
}
