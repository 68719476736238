.Frame {
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
	z-index: 0;

	.preview {
		border-radius: 2rem;
		outline: 2rem solid rgb(33, 33, 33);
		aspect-ratio: 9/16;
		height: 80vh;
		overflow: hidden;
		scale: var(--frame-scale);
		width: 1080px;
		height: 1980px;
		min-height: 1980px;
		min-width: 1080px;
		padding: 15rem 4rem;
		margin-top: -5vh;
		margin-top: -5svh;
		padding-top: 18rem;
		background-color: black;

		.infos {
			transition-duration: 0.5s;

			.title {
				font-size: 6rem;
				line-height: 1;
				width: 100%;
				text-transform: capitalize;
				display: block;
				font-weight: 400;
				margin-bottom: 2.5rem;

				&:empty {
					border-bottom: 1px solid white;
				}
			}

			.text,
			.more {
				font-size: 3.7rem;
				line-height: 1;
				display: block;
				font-weight: 200;

				&:empty {
					border-bottom: 1px solid white;
				}
			}
		}

		::placeholder {
			color: #ffffff99;
		}

		.gradient-txt {
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			.title,
			.text,
			.more {
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}

		.coverBg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: black;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			filter: blur(var(--cover-bg-blur));
		}
	}

	@media screen and (min-width: 1000px) {
		.preview {
			margin-top: 0vh;
		}
	}
}

.generated-frame {
	position: absolute;
	top: 1000%;
	left: 0;
	z-index: 0;
	border-style: none;
	border-color: #e5e7eb;
	box-sizing: border-box;

	.preview {
		border-radius: 0 !important;
		scale: 1 !important;
	}

	.gen-opacity-0 {
		opacity: 0;
	}

	.gen-hide {
		opacity: 0;
		display: none;
	}

	.title:empty,
	.more:empty,
	.text:empty {
		opacity: 0;
	}

	& *,
	:after,
	:before {
		border-style: none;
		border-color: #e5e7eb;
		box-sizing: border-box;
	}
}
