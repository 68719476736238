body {
	overflow: hidden;
}

.App {
	height: 100svh;
	--slider-thumb-color: #0070c7;
	--slider-active-color: #008fff;
}

button:disabled {
	cursor: not-allowed !important;
}

.dark {
	.Toastify__close-button {
		color: white !important;
	}
}

.h-svh {
	height: 100%;
	height: 100svh;
}

.SaveOverlay {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 100000;

	.cont {
	}
}
