.Search {
	.Result {
		max-width: 300px;

		transition-duration: 0.5s;
		.image {
			width: 40px;
			height: 40px;
			min-width: 40px;

			.play-btn {
				font-size: 1.2rem;
				font-weight: 100;
				opacity: 0;
				background-color: rgba(0, 0, 0, 0.4);
				text-align: center;
			}
		}

		&:hover,
		&.playing {
			.play-btn {
				opacity: 1;
			}
		}

		.title,
		.text {
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			word-break: keep-all;
			white-space: nowrap;
			font-weight: 400;
		}
	}
}
