.CustomGPicker {
	.input_rgba .input_rgba-wrap .input_rgba-alpha .input_rgba-alpha-label {
		top: 12%;
	}

	.input_rgba .input_rgba-wrap .input_rgba-hex .input_rgba-hex-label {
		top: 8px;
	}

	input {
		background-color: #efefef !important;
		box-shadow: none !important;
	}

	.popup_tabs {
		width: 100% !important;
	}

	&.dark {
		.popup_tabs {
			background-color: #121212;
		}
		.popup_tabs-header {
			background-color: #282828;
		}
		.popup_tabs-header .popup_tabs-header-label {
			color: #a2a2a2;
		}
		.popup_tabs-header .popup_tabs-header-label-active {
			background-color: black;
		}

		.popup_tabs-body-item > div {
			background-color: transparent;
		}

		.color-picker-panel,
		.colorpicker {
			background-color: transparent;
		}

		input {
			background-color: #353535 !important;
			color: white !important;
		}
	}
}
