@font-face {
	font-family: "Abril Fatface";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/abrilfatface/v19/zOL64pLDlL1D99S8g8PtiKchm-A.ttf)
		format("truetype");
}
@font-face {
	font-family: "Amatic SC";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/amaticsc/v24/TUZyzwprpvBS1izr_vO0DQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Amatic SC";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/amaticsc/v24/TUZ3zwprpvBS1izr_vOMscG6eQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Asset";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/asset/v24/SLXGc1na-mM4cWIm.ttf)
		format("truetype");
}
@font-face {
	font-family: "Baloo";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdgazapv.ttf)
		format("truetype");
}
@font-face {
	font-family: "Baloo";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdgozapv.ttf)
		format("truetype");
}
@font-face {
	font-family: "Baloo";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdj9yqpv.ttf)
		format("truetype");
}
@font-face {
	font-family: "Bebas Neue";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXooxW4.ttf)
		format("truetype");
}
@font-face {
	font-family: "Bungee";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/bungee/v11/N0bU2SZBIuF2PU_ECg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Cairo Play";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1zHYq9w.ttf)
		format("truetype");
}
@font-face {
	font-family: "Cairo Play";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1knYq9w.ttf)
		format("truetype");
}
@font-face {
	font-family: "Cairo Play";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1oHYq9w.ttf)
		format("truetype");
}
@font-face {
	font-family: "Cairo Play";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1dXEq9w.ttf)
		format("truetype");
}
@font-face {
	font-family: "Cairo Play";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/cairoplay/v6/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1O3Eq9w.ttf)
		format("truetype");
}
body {
	--google-font-color-cairoplay: none;
}
@font-face {
	font-family: "Caveat";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9SII.ttf)
		format("truetype");
}
@font-face {
	font-family: "Caveat";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjcB9SII.ttf)
		format("truetype");
}
@font-face {
	font-family: "Caveat";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/caveat/v17/WnznHAc5bAfYB2QRah7pcpNvOx-pjRV6SII.ttf)
		format("truetype");
}
@font-face {
	font-family: "Climate Crisis";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/climatecrisis/v4/wEOpEB3AntNeKCPBVW9XOKlmp3AUgWFN1DvIvcM0gFp6jQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro Italic";
	font-style: italic;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi7Ke5s7.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro Italic";
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6Ue5s7.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro Italic";
	font-style: italic;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6me5s7.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro Italic";
	font-style: italic;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5zfJs7.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro Italic";
	font-style: italic;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi49fJs7.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZkG18OA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm18OA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZ_G18OA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZKWp8OA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Crimson Pro";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/crimsonpro/v23/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZZ2p8OA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Fascinate";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/fascinate/v21/z7NWdRrufC8XJK0IIEli1A.ttf)
		format("truetype");
}
@font-face {
	font-family: "Grand Hotel";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/grandhotel/v13/7Au7p_IgjDKdCRWuR1azpmQN.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato Italic";
	font-style: italic;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u-w4BMUTPHjxsIPy-v.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato Italic";
	font-style: italic;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u_w4BMUTPHjxsI9w2PHA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato Italic";
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u8w4BMUTPHjxswWw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato Italic";
	font-style: italic;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u_w4BMUTPHjxsI5wqPHA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato Italic";
	font-style: italic;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u_w4BMUTPHjxsI3wiPHA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u8w4BMUTPHh30wWw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USew8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHvxk.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVew8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lato";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50Xew8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata Italic";
	font-style: italic;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8obXWSQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata Italic";
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8_7XWSQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata Italic";
	font-style: italic;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8zbXWSQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata Italic";
	font-style: italic;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8GLLWSQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata Italic";
	font-style: italic;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8VrLWSQ.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbE-_F_Y.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_F_Y.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbCO_F_Y.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbPa4F_Y.ttf)
		format("truetype");
}
@font-face {
	font-family: "Literata";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/literata/v34/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbLi4F_Y.ttf)
		format("truetype");
}
@font-face {
	font-family: "Lobster";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/lobster/v28/neILzCirqoswsqX9_oU.ttf)
		format("truetype");
}
@font-face {
	font-family: "Monoton";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/monoton/v15/5h1aiZUrOngCibe4fkY.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat Italic";
	font-style: italic;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat Italic";
	font-style: italic;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat Italic";
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat Italic";
	font-style: italic;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat Italic";
	font-style: italic;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat Italic";
	font-style: italic;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX8.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf)
		format("truetype");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf)
		format("truetype");
}
@font-face {
	font-family: "Oswald";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUE.ttf)
		format("truetype");
}
@font-face {
	font-family: "Oswald";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUE.ttf)
		format("truetype");
}
@font-face {
	font-family: "Oswald";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUE.ttf)
		format("truetype");
}
@font-face {
	font-family: "Oswald";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUE.ttf)
		format("truetype");
}
@font-face {
	font-family: "Pacifico";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins Italic";
	font-style: italic;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE3tF.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins Italic";
	font-style: italic;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21llEA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins Italic";
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLedw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins Italic";
	font-style: italic;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hlEA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins Italic";
	font-style: italic;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15lEA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins Italic";
	font-style: italic;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xlEA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTedw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1s.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1s.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1s.ttf)
		format("truetype");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1s.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt Italic";
	font-style: italic;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_7XJnvUD7dzB2KZeJ8TkM.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt Italic";
	font-style: italic;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeK0bGMr.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt Italic";
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2KZdoY.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt Italic";
	font-style: italic;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLsbWMr.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt Italic";
	font-style: italic;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKka2Mr.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt Italic";
	font-style: italic;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKcaWMr.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2CA9oY.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cy_g4bg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB26Zw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Ck_k4bg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C2_84bg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Prompt";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C4_04bg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Quicksand";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo18E.ttf)
		format("truetype");
}
@font-face {
	font-family: "Quicksand";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf)
		format("truetype");
}
@font-face {
	font-family: "Quicksand";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18E.ttf)
		format("truetype");
}
@font-face {
	font-family: "Quicksand";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/quicksand/v30/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18E.ttf)
		format("truetype");
}
@font-face {
	font-family: "Reem Kufi Fun";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYj3nCg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Reem Kufi Fun";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYR3nCg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Reem Kufi Fun";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChobE2XCg.ttf)
		format("truetype");
}
body {
	--google-font-color-reemkufifun: none;
}
@font-face {
	font-family: "Righteous";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/righteous/v13/1cXxaUPXBpj2rGoU7C9mjw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Italic";
	font-style: italic;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzc.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Italic";
	font-style: italic;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Italic";
	font-style: italic;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xP.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Italic";
	font-style: italic;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Italic";
	font-style: italic;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Italic";
	font-style: italic;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxP.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAw.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 100;
	src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Roboto Slab";
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/robotoslab/v24/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Shadows Into Light";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/shadowsintolight/v15/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Shrikhand";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/shrikhand/v11/a8IbNovtLWfR7T7bMJwbBA.ttf)
		format("truetype");
}
@font-face {
	font-family: "Solitreo";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/solitreo/v1/r05YGLlS5a9KYsyNO8up.ttf)
		format("truetype");
}
@font-face {
	font-family: "Sono";
	font-style: normal;
	font-weight: 300;
	src: url(https://fonts.gstatic.com/s/sono/v4/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxV6EWdEg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Sono";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/sono/v4/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVtkWdEg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Sono";
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/sono/v4/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVhEWdEg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Sono";
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/sono/v4/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVUUKdEg.ttf)
		format("truetype");
}
@font-face {
	font-family: "Special Elite";
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/specialelite/v18/XLYgIZbkc4JPUL5CVArUVL0nhnc.ttf)
		format("truetype");
}
