.Image {
	width: 70%;
	border-radius: 0.5rem;
	position: relative;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.15);
	font-size: 1rem;

	.upload-icon {
		color: lightgray;
		font-size: 3rem;
	}

	.lock-btn {
		gap: 0.25rem;
		top: 5px;
		left: 10px;
	}

	.rotate-btn {
		gap: 0.25rem;
		top: 5px;
		right: 10px;
	}

	.delete-btn {
		gap: 0.25rem;
		bottom: 10px;
		left: 10px;
	}

	&.framed {
		border-radius: 3rem;

		.upload-icon {
			font-size: 10rem;
			color: lightgray;
		}

		button {
			font-size: 3rem;
			line-height: 3.25rem;
			gap: 1rem;
			top: 1.5rem;
		}

		.lock-btn {
			left: 1.5rem;
		}

		.rotate-btn {
			right: 1.5rem;
		}

		.delete-btn {
			top: unset;
			bottom: 1.5rem;
			left: 1.5rem;
		}

		.upload-icon {
			font-size: 10rem;
			line-height: 10rem;
		}
	}

	.overlay {
		transition-duration: 0.3s;
		opacity: 1;
		width: 100%;
		height: 100%;
	}

	.file-input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 10;
		cursor: pointer;
	}

	&.hasImage {
		.overlay {
			opacity: 0;
		}
		.upload-icon {
			color: white;
		}
		button {
			opacity: 0;
		}
		&:hover {
			.overlay {
				background-color: rgba(0, 0, 0, 0.334);
				opacity: 1;
			}

			button {
				opacity: 1;
			}
		}
	}
}
