.Tabs {
	position: relative;
	background-color: #ffffff;
	box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.12);
	border-radius: 6px;
	min-width: 260px;
	display: flex;
	flex-direction: column-reverse;

	.tabs-header {
		width: 100%;
		box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.04);
		background-color: #f6f7f9;
		display: flex;
		border-radius: 6px;
		border-top-right-radius: 0px;
		border-top-left-radius: 0px;
		overflow: hidden;
	}

	.tabs-header .tabs-header-label {
		width: 100%;
		min-width: 100px;
		font-size: 0.65rem;
		line-height: 1;
		padding: 0.6rem 1rem;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: 0.5px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		text-transform: uppercase;
	}
	.tabs-header .tabs-header-label.active {
		background-color: #ffffff;
		cursor: default;
		color: #312e55;
	}
	.tabs-body {
		order: 2;
		padding: 16px;
		display: flex;
		justify-content: center;

		& > * {
			width: 100%;
		}
	}
}

@media screen and (min-width: 1000px) {
	.Tabs {
		flex-direction: column;
		min-width: 300px;

		.tabs-header {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
	}
}
