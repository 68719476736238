.MusicTag {
	max-width: 70%;
	padding: 14px;
	border-radius: 12px;
	font-size: 2.2rem;
	line-height: 2.9rem;
	overflow: hidden;
	color: black;

	.image {
		display: flex;
		width: 100px;
		height: 100px;
		background-color: white;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 12px;
	}
	.title {
		font-weight: 600;
	}

	.text {
		font-weight: 300;
	}

	.text,
	.title {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
